<template>
  <Confirm
    :value="value"
    icon="mdi-history"
    icon-color="orange"
    @input="handleInput"
  >
    <template #title>Restore Form Entry?</template>

    <template #default>
      <div v-if="multipleRestore" class="q-py-md">
        <p>
          Are you sure you want to restore
          <b> " {{ numberOfRestore }} Form Entrys" </b>?. You can always delete
          the restored form entry from the browse page.
        </p>
      </div>
      <div v-else class="q-py-md">
        <p>
          Are you sure you want to restore the form
          <b> "Entry #{{ formEntryId }}" </b>?. You can always delete the
          restored form entry from the browse page.
        </p>
      </div>
    </template>

    <template #footer>
      <div v-if="multipleRestore" class="displayflex">
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="orange"
          @click="handleNo"
        />
        <BaseButton label="yes" color="orange" @click="handleMultiYes" />
      </div>
      <div v-else class="displayflex">
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="orange"
          @click="handleNo"
        />
        <BaseButton label="yes" color="orange" @click="handleYes" />
      </div>
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";

export default {
  name: "ConfirmRestoreFormEntry",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    formEntryId: {
      type: Number,
      default: 0,
    },

    multipleRestore: {
      type: Boolean,
      default: false,
    },

    numberOfRestore: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("restore", { isDeleted: false });
    },

    handleMultiYes() {
      this.$emit("input", false);
      this.$emit("multirestore");
    },
  },
};
</script>

<style lang="scss" scoped>
.displayflex {
  display: flex;
}
</style>
