<template>
  <Confirm
    :value="value"
    icon="eva-trash-2-outline"
    icon-color="red"
    @input="handleInput"
  >
    <template #title>Delete Form Entry?</template>

    <template #default>
      <div v-if="mode === 'TRASH'" class="q-py-md">
        <div v-if="isAllSelected">
          <p v-if="!confirmALLDelete">
            Are you sure you want to delete
            <b> "{{ numberOfFilesDelete }} Form Entry" </b>?.
          </p>
          <div v-if="numberOfFilesDelete !== totalItems" class="displayflex">
            <CheckboxField
              v-model="confirmALLDelete"
              @input="confirmDeleteAll"
            />
            <P
              >Do you want to delete all
              <b>" {{ totalItems }} Entries "</b> presents in this form.
            </P>
          </div>
          <p>You can not restore the deleted form entry.</p>
        </div>
        <p v-else-if="isMultiDeleteConfirmationVisible">
          Are you sure you want to delete
          <b> "{{ numberOfFilesDelete }} Form Entry" </b>?. You can not restore
          the deleted form entry.
        </p>
      </div>
      <div v-else class="q-py-md">
        <div v-if="isAllSelected">
          <p v-if="!confirmALLDelete">
            Are you sure you want to delete
            <b> "{{ numberOfFilesDelete }} Form Entry" </b>?.
          </p>
          <div class="displayflex">
            <CheckboxField
              v-model="confirmALLDelete"
              @input="confirmDeleteAll"
            />
            <P
              >Do you want to delete all
              <b>" {{ totalItems }} Entries "</b> presents in this form.
            </P>
          </div>
          <p>
            You can always restore the deleted form entry from the trash page.
          </p>
        </div>
        <p v-else-if="isMultiDeleteConfirmationVisible">
          Are you sure you want to delete
          <b> "{{ numberOfFilesDelete }} Form Entry" </b>?. You can always
          restore the deleted form entry from the trash page.
        </p>

        <p v-else>
          Are you sure you want to delete the form
          <b> "Entry #{{ formEntryId }}" </b>?. You can always restore the
          deleted form entry from the trash page.
        </p>
      </div>
    </template>

    <template #footer>
      <div v-if="isAllSelected" class="displayflex">
        <BaseButton
          is-flat
          label="No"
          class="q-mr-sm"
          color="red"
          @click="handleNo"
        />
        <BaseButton label="Yes" color="red" @click="handleMultiYes" />
      </div>

      <div v-else-if="isMultiDeleteConfirmationVisible" class="displayflex">
        <BaseButton
          is-flat
          label="No"
          class="q-mr-sm"
          color="red"
          @click="handleNo"
        />
        <BaseButton label="Yes" color="red" @click="handleUniqueYes" />
      </div>

      <div v-else class="displayflex">
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="red"
          @click="handleNo"
        />
        <BaseButton label="yes" color="red" @click="handleYes" />
      </div>
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";

export default {
  name: "ConfirmDeleteFormEntry",

  components: { Confirm, CheckboxField },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    formEntryId: {
      type: Number,
      default: 0,
    },

    isMultiDeleteConfirmationVisible: {
      type: Boolean,
      required: false,
      default: false,
    },

    numberOfFilesDelete: {
      type: Number,
      default: 0,
    },

    isAllSelected: {
      type: Boolean,
      default: false,
      required: false,
    },

    totalItems: {
      type: Number,
      default: 0,
    },

    mode: {
      type: String,
      default: "",
    },

    confirmALLDelete: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    handleYes() {
      this.$emit("input", false);
      this.$emit("delete", { isDeleted: true });
    },

    handleMultiYes() {
      this.$emit("input", false);
      this.$emit("multidelete");
    },

    confirmDeleteAll() {
      this.$emit("confirmAllDelete", this.confirmALLDelete);
    },

    handleUniqueYes() {
      this.$emit("input", false);
      this.confirmALLDelete = false;
      this.$emit("confirmAllDelete", this.confirmALLDelete);
      this.$emit("multidelete");
    },
  },
};
</script>

<style lang="scss" scoped>
.displayflex {
  display: flex;
}
</style>
