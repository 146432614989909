<template>
  <div id="form-view" :style="{ width: formWidth }">
    <!-- form meta -->

    <div class="header">
      <div class="col">
        <div class="title-1">{{ formName }}</div>
        <div class="description">{{ formDescription }}</div>
      </div>

      <BaseActionButton
        is-flat
        :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
        @click="toggleWidth"
      />
      <ThemeSwitcher class="q-ml-sm" :no-border="false" />
    </div>

    <!-- ... -->

    <!-- form -->

    <RenderForm
      v-if="form"
      :panels="panels"
      :secondary-panels="secondaryPanels"
      :form-settings="formSettings"
      :form-model="formModel"
      is-readonly
    />

    <!-- ... -->

    <!-- footer -->

    <div class="footer">
      <div class="label">Powered by</div>
      <img src="@/assets/logo/logo-light.png" alt="logo" />
    </div>

    <!-- ... -->
  </div>
</template>

<script>
import { auth, form } from "@/api/factory.js";
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";

export default {
  name: "FormEntriesView",

  components: { ThemeSwitcher, RenderForm },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    formId: {
      type: String,
      required: true,
    },

    entryId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: null,
      formWidth: "720px",
      formModel: {},
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100%";
    },

    formName() {
      if (!this.form) {
        return "";
      }

      return this.formSettings.general.name;
    },

    formDescription() {
      if (!this.form) {
        return "";
      }

      return this.formSettings.general.description;
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }

      return this.form.settings;
    },

    formFields() {
      const fields = [];

      if (!this.form) {
        return fields;
      }

      for (let panel of this.form.panels) {
        for (let field of panel.fields) {
          fields.push({
            id: field.id,
            type: field.type,
            label: field.label,
          });
        }
      }

      return fields;
    },
  },

  watch: {
    tenantId: {
      immediate: true,
      handler: "getSession",
    },
  },

  methods: {
    toggleWidth() {
      this.formWidth = this.formWidth === "100%" ? "720px" : "100%";
    },

    async getSession() {
      const { error } = await auth.externalLogin(this.tenantId, 1);
      if (error) {
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getForm();
    },

    async getForm() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(this.formId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getFormEntry(payload);
    },

    async getFormEntry(formdata) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntry(
        this.formId,
        this.entryId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.form = JSON.parse(formdata.formJson);
        this.formModel = payload.map((entry) => ({
          id: entry.itemid,
          itemid: `Entry #${entry.itemid}`,
          createdBy: entry.createdByEmail,
          createdAt: this.$day.format(entry.createdAt),
          modifiedBy: entry.modifiedByEmail,
          modifiedAt: this.$day.format(entry.modifiedAt),
          ...this.getFormFieldsData(entry),
        }))[0];
      } else {
        this.$alert.error("No Data Found");
      }
    },

    getFormFieldsData(entry) {
      const data = {};

      for (let field of this.formFields) {
        const complexTypes = [
          "TABLE",
          "MATRIX",
          "FILL_IN_THE_BLANKS",
          "TABS",
          "POPUP",
        ];

        data[field.id] = complexTypes.includes(field.type)
          ? JSON.parse(entry[field.id])
          : entry[field.id];
      }

      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
#form-view {
  margin: auto;
  padding: 16px;

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }
}
</style>
